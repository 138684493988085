import React, { useEffect, useState } from "react";
import dlclogo from "../assets/LOGO_WHITE.png";
import dlclogoBlack from "../assets/LOGO_NERO.png";

import styles from "./Home.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { useParams } from "react-router-dom";
import axiosHelper from "../helpers/axiosHelper";
import { ContactData } from "../global";
import { useStoreState } from "pullstate";
import BusinessCardStore, {
  setCurrentContact,
} from "../store/BusinessCardStore";
import { getCurrentContact } from "../store/Selector";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faCloudArrowDown,
  faGlobe,
  faPhone,
  faTv,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import PopUpExample from "../components/PopUpExample/PopUpExample";

import phoneblack from "../assets/phoneblack.png";
import phonewhite from "../assets/phonewhite.png";

import mailblack from "../assets/mailblack.png";
import mailwhite from "../assets/mailwhite.png";

import pcblack from "../assets/pcblack.png";
import pcwhite from "../assets/pcwhite.png";

import exchangeBlack from "../assets/exchangeblack.png";
import exchangeWhite from "../assets/exchangewhite.png";
import PopUpExchange from "../components/PopUpExchange/PopUpExchange";

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const contact = useStoreState(BusinessCardStore, getCurrentContact);
  const [popup, setPopup] = useState(false);
  const [popupExchange, setPopupExchange] = useState(false);
  // const [contact, setContact] = useState<Contact>();

  const [language, setLanguage] = useState("it");

  const [lngOpen, setLngOpen] = useState(false);

  const [base64Img, setBase64Img] = useState("");

  useEffect(() => {
    console.log(params.name);
    i18n.changeLanguage("en");
    getContact();
  }, [params.name]);

  function convertImageToBase64(imgUrl?: string, callback?: any) {
    if (imgUrl) {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx && ctx.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL();
        callback && callback(dataUrl);
      };
      image.src = imgUrl;
    } else {
      console.log("no image");
    }
  }

  useEffect(() => {
    console.log("useeffect 2");

    convertImageToBase64(contact.imageurl, (res: any) => {
      setBase64Img(res.substring(22, res.length - 1));
    });
  }, [contact]);

  const openModal = () => {
    if (os === "iOS") {
      setPopup(!popup);
    } else {
      downloadContact();
    }
  };

  const openModalExchange = () => {
    setPopupExchange(!popupExchange);
  };

  const downloadContact = () => {
    if (contact) {
      let vcard = `BEGIN:VCARD\nVERSION:3.0\nFN;CHARSET=UTF-8:`;

      if (contact.name && contact.surname) {
        vcard =
          vcard +
          `BEGIN:VCARD\nVERSION:3.0\nFN;CHARSET=UTF-8:` +
          contact.name +
          ` ` +
          contact.surname +
          `\nN;CHARSET=UTF-8:` +
          contact.surname +
          `;` +
          contact.name +
          `;;;`;
      }
      if (contact.email) {
        vcard =
          vcard + `\nEMAIL;CHARSET=UTF-8;type=email,INTERNET:` + contact.email;
      }
      if (contact.workmail) {
        vcard =
          vcard +
          `\nEMAIL;CHARSET=UTF-8;type=work email,INTERNET:` +
          contact.workmail;
      }
      if (contact.imageurl) {
        vcard = vcard + `\nPHOTO;ENCODING=b;TYPE=PNG:` + base64Img;
      }
      if (contact.website) {
        vcard = vcard + `\nURL;TYPE=website;CHARSET=UTF-8:` + contact.website;
      }
      if (contact.phone) {
        vcard = vcard + `\nTEL;TYPE=phone number,VOICE:` + contact.phone;
      }
      if (contact.role) {
        vcard = vcard + `\nROLE;CHARSET=UTF-8:` + contact.role;
      }
      if (contact.company) {
        vcard = vcard + `\nORG;CHARSET=UTF-8:` + contact.company;
      }
      if (contact.facebook) {
        vcard = vcard + "\nX-SOCIALPROFILE;TYPE=facebook:" + contact.facebook;
      }
      if (contact.instagram) {
        vcard = vcard + `\nX-SOCIALPROFILE;TYPE=Instagram:` + contact.instagram;
      }
      if (contact.linkedin) {
        vcard = vcard + `\nX-SOCIALPROFILE;TYPE=LinkedIn:` + contact.linkedin;
      }

      // +"\nNICKNAME:Cecche"+

      // "\nPHOTO;TYPE=JPEG;VALUE=URI:" +
      // contact.img +

      // "\nX-SOCIALPROFILE;TYPE=facebook:" +
      // contact.facebook +
      // `\nX-SOCIALPROFILE;TYPE=Instagram:` + contact.instagram + ;
      vcard = vcard + `\nNOTE;CHARSET=UTF-8:Created by DLC Tech`;
      vcard = vcard + `\nEND:VCARD`;
      // console.log(vcard);
      let blob = new Blob([vcard], { type: "text/vcard" });

      let url = URL.createObjectURL(blob);
      const newLink = document.createElement("a");
      newLink.download = contact.name + ".vcf";
      newLink.textContent = contact.name;
      newLink.href = url;
      newLink.click();
    }
  };

  useEffect(() => {
    if (params.name) {
      console.log(contact);
      getContact();
    }
  }, []);

  const getContact = async () => {
    if (params.name) {
      await axiosHelper
        .getContact(params.name)
        .then((response: ContactData) => {
          // console.log(response);
          console.log(navigator.userAgent);
          setCurrentContact(response);
        })
        .catch((error) => console.log(error));
    }
  };

  const os = (() => {
    if (/windows/i.test(navigator.userAgent)) {
      return "Windows";
    } else if (/iphone/i.test(navigator.userAgent)) {
      return "iOS";
    } else if (/ipad/i.test(navigator.userAgent)) {
      return "iOS";
    } else if (/macintosh/i.test(navigator.userAgent)) {
      return "Mac OS";
    }
    // more user agents to detect...
  })();

  return (
    <>
      {contact.name === "" && (
        <>
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: "100vh", backgroundColor: "white" }}
          >
            <img src={dlclogoBlack} style={{ maxWidth: "80vw" }} />
          </div>
        </>
      )}
      {contact.name !== "" && (
        <>
          <div
            className="d-none d-sm-flex  flex-column align-items-center justify-content-start col-12"
            style={{
              minHeight: "100vh",
              backgroundColor: `${
                contact.mode === "NIGHT" ? "black" : "white"
              }`,
            }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center col-3 ">
              {/* LOGO */}
              <div
                className={` mt-5 d-flex flex-row align-items-center justify-content-center col-12 ${styles.logoContainer}`}
              >
                {contact.logourl && (
                  <img alt="logo" src={contact.logourl} className="mb-4 " />
                )}
              </div>
              {/* IMAGE */}
              <div
                className={`d-flex mt-4 flex-row align-items-center justify-content-center col-12 ${styles.imageContainer}`}
              >
                {contact.imageurl && (
                  <img
                    id="img"
                    alt="img"
                    src={
                      contact?.imageurl !== null ? contact.imageurl : mailwhite
                    }
                    style={{
                      border: `${
                        contact.mode === "LIGHT"
                          ? "4px solid black"
                          : "7px solid white"
                      }`,
                    }}
                  />
                )}
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start mt-5 col-12  ">
                <span
                  className={`sub-title-business ${
                    contact.mode === "NIGHT" ? "color-white" : "color-black"
                  }`}
                >
                  {contact?.name + ` ` + contact?.surname}
                </span>
                <span
                  className={`text-business mt-4 text-center ${
                    contact.mode === "NIGHT" ? "color-white" : "color-black"
                  }`}
                >
                  {/* CEO & Co-Founder at DLC Tech */}
                  {contact?.role}
                  {/* <br /> <br />
               {contact?.company} */}
                </span>
                <div className="d-flex flex-row align-items-center justify-content-around col-12 ">
                  <div
                    className={`mt-5  d-flex flex-row align-items-center justify-content-center button-text col-5 ${
                      contact.mode === "NIGHT"
                        ? `${styles.addButtonGreen}`
                        : `${styles.addButtonBlue}`
                    }`}
                    // onClick={downloadContact}
                    onClick={openModal}
                  >
                    <FontAwesomeIcon
                      icon={faCloudArrowDown as IconProp}
                      style={{ fontSize: "20px" }}
                      className="me-2"
                    />
                    {t(`HOME_ADD_CONTACT`)}
                  </div>
                  <div
                    className={`mt-5  d-flex flex-row align-items-center justify-content-center button-text col-5  ${
                      contact.mode === "NIGHT"
                        ? `${styles.exchangeButtonBlack}`
                        : `${styles.exchangeButtonWhite}`
                    }`}
                    // onClick={downloadContact}
                    onClick={openModalExchange}
                  >
                    <div className={`${styles.btniconContainer} me-2`}>
                      <img
                        src={
                          contact.mode === "NIGHT"
                            ? exchangeWhite
                            : exchangeBlack
                        }
                      />
                    </div>
                    {t(`HOME_EXCHANGE`)}
                  </div>
                </div>
              </div>
              <div className="d-flex  flex-column align-items-start justify-content-start mt-5 ps-5 col-12 ">
                {contact.phone && (
                  <a
                    href={`tel:${contact.phone}`}
                    style={{ textDecoration: "none" }}
                    className=" col-12"
                  >
                    <span
                      className={`text-business  d-flex flex-row align-items-center justify-content-start col-12   ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      <div className={`${styles.iconContainer} me-3  col-1`}>
                        <img
                          src={
                            contact.mode === "NIGHT" ? phonewhite : phoneblack
                          }
                        />
                      </div>
                      <span className="col-11  d-flex flex-row align-items-center justify-content-center">
                        {contact?.phone}
                      </span>
                    </span>
                  </a>
                )}
                {contact.email && (
                  <a
                    href={`mailto:${contact.email}`}
                    style={{ textDecoration: "none" }}
                    className="col-12"
                  >
                    <span
                      className={`text-business mt-3  d-flex flex-row align-items-center justify-content-start col-12  ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      <div className={`${styles.iconContainer} me-3 col-1`}>
                        <img
                          src={contact.mode === "NIGHT" ? mailwhite : mailblack}
                        />
                      </div>
                      <span className="col-11 d-flex flex-row align-items-center justify-content-center ">
                        {contact?.email}
                      </span>
                    </span>
                  </a>
                )}
                {contact.website && (
                  <a
                    href={`${contact.website}`}
                    style={{ textDecoration: "none" }}
                    className="col-12"
                  >
                    <span
                      className={`text-business mt-3 d-flex flex-row align-items-center justify-content-center col-12 ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      {/* <img src={websiteIcon} style={{color:'#ffffff', maxHeight:'30px'}}/> */}
                      <div className={`${styles.iconContainer} me-3 col-1`}>
                        <img
                          src={contact.mode === "NIGHT" ? pcwhite : pcblack}
                        />
                      </div>
                      <span className="col-11 d-flex flex-row align-items-center justify-content-center">
                        {contact?.website.substring(8, contact.website.length)}
                      </span>
                    </span>
                  </a>
                )}

                {contact.label_link1 && contact.link1 && (
                  <a
                    href={`${contact.link1}`}
                    style={{ textDecoration: "underline" }}
                  >
                    <span
                      className={`text-business mt-3 d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      {/* <img src={websiteIcon} style={{color:'#ffffff', maxHeight:'30px'}}/> */}
                      {contact?.label_link1}
                    </span>
                  </a>
                )}

                {contact.label_link2 && contact.link2 && (
                  <a
                    href={`${contact.link2}`}
                    style={{ textDecoration: "underline" }}
                  >
                    <span
                      className={`text-business mt-3 d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      {/* <img src={websiteIcon} style={{color:'#ffffff', maxHeight:'30px'}}/> */}
                      {contact?.label_link2}
                    </span>
                  </a>
                )}
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center col-12">
                {(contact?.facebook ||
                  contact?.linkedin ||
                  contact?.instagram ||
                  contact?.whatsapp !== 0) && (
                  <span
                    className={`text-business fw-bold mt-5 ${
                      contact.mode === "NIGHT" ? "color-white" : "color-black"
                    }`}
                  >
                    Social:
                  </span>
                )}
                <div className=" d-flex  flex-row align-items-center justify-content-around col-8  mt-5 pb-5">
                  {contact.linkedin && (
                    <span
                      className={`${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      }`}
                    >
                      <FontAwesomeIcon
                        onClick={() => {
                          contact?.linkedin &&
                            window.location.replace(contact?.linkedin);
                        }}
                        icon={faLinkedin as IconProp}
                        fontSize={"6vh"}
                      />
                    </span>
                  )}
                  {contact.instagram && (
                    <span
                      className={`bg-white d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      }`}
                      style={{
                        borderRadius: "50%",
                        height: "6vh",
                        width: "6vh",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faInstagram as IconProp}
                        onClick={() => {
                          contact?.instagram &&
                            window.location.replace(contact?.instagram);
                        }}
                        fontSize={"4.5vh"}
                        className="color-black"
                      />
                    </span>
                  )}
                  {contact.facebook &&
                    (console.log(contact.facebook),
                    (
                      <span>
                        <FontAwesomeIcon
                          icon={faFacebook as IconProp}
                          onClick={() => {
                            contact?.facebook &&
                              window.location.replace(contact?.facebook);
                          }}
                          fontSize={"6vh"}
                          style={
                            contact.mode === "LIGHT"
                              ? { color: "black" }
                              : { color: "white" }
                          }
                        />
                      </span>
                    ))}
                  {contact.whatsapp !== 0 && (
                    <span
                      className={`bg-white d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "LIGHT" ? "color-black" : "color-white"
                      }`}
                      style={{
                        borderRadius: "50%",
                        height: "6vh",
                        width: "6vh",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faWhatsapp as IconProp}
                        onClick={() => {
                          contact?.whatsapp !== 0 &&
                            window.location.replace(
                              `https://wa.me/${contact.phone.substring(
                                1,
                                contact.phone.length
                              )}`
                            );
                        }}
                        fontSize={"4.5vh"}
                        className="color-black"
                      />
                    </span>
                  )}
                </div>
              </div>
              <div
                className={` mt-5 ${
                  contact.mode === "NIGHT" ? "bg-black" : "bg-white"
                } ${styles.powered}`}
                onClick={() => (window.location.href = "https://dlctech.it")}
              >
                <span
                  className={`${
                    contact.mode === "NIGHT" ? "color-white" : "color-black"
                  } `}
                >
                  powered by
                </span>
                <img
                  className="ms-2"
                  src={contact.mode === "NIGHT" ? dlclogo : dlclogoBlack}
                />
              </div>
            </div>
          </div>

          {/* MOBILE */}
          <div className=" overflow-hidden  d-flex d-sm-none flex-column align-items-center justify-content-center  col-12 ">
            <main
              className={`containter-md col-12 color-white    `}
              style={{
                minHeight: "90vh",
                backgroundColor: `${
                  contact.mode === "NIGHT" ? "black" : "white"
                }`,
              }}
            >
              <div
                className={` mt-5 d-flex flex-row align-items-center justify-content-center col-12  ${styles.logoContainer}`}
              >
                {contact.logourl && (
                  <img alt="logo" src={contact.logourl} className="mb-4" />
                )}
              </div>

              <div
                className={`d-flex mt-4 flex-row align-items-center justify-content-center col-12 ${styles.imageContainer}`}
              >
                {contact.imageurl && (
                  <img
                    id="img"
                    alt="img"
                    src={
                      contact?.imageurl !== null ? contact.imageurl : mailwhite
                    }
                    style={{
                      border: `${
                        contact.mode === "LIGHT"
                          ? "4px solid black"
                          : "7px solid white"
                      }`,
                    }}
                  />
                )}
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start mt-5  ">
                <span
                  className={`sub-title-business ${
                    contact.mode === "NIGHT" ? "color-white" : "color-black"
                  }`}
                >
                  {contact?.name + ` ` + contact?.surname}
                </span>
                <span
                  className={`text-business mt-4 text-center ${
                    contact.mode === "NIGHT" ? "color-white" : "color-black"
                  }`}
                >
                  {/* CEO & Co-Founder at DLC Tech */}
                  {contact?.role}
                  {/* <br /> <br />
               {contact?.company} */}
                </span>
                <div className="d-flex flex-row align-items-center justify-content-around col-12 ">
                  <div
                    className={`mt-5  d-flex flex-row align-items-center justify-content-center button-text col-5 ${
                      contact.mode === "NIGHT"
                        ? `${styles.addButtonGreen}`
                        : `${styles.addButtonBlue}`
                    }`}
                    // onClick={downloadContact}
                    onClick={openModal}
                  >
                    <FontAwesomeIcon
                      icon={faCloudArrowDown as IconProp}
                      style={{ fontSize: "20px" }}
                      className="me-2"
                    />
                    {t(`HOME_ADD_CONTACT`)}
                  </div>
                  <div
                    className={`mt-5  d-flex flex-row align-items-center justify-content-center button-text col-5  ${
                      contact.mode === "NIGHT"
                        ? `${styles.exchangeButtonBlack}`
                        : `${styles.exchangeButtonWhite}`
                    }`}
                    // onClick={downloadContact}
                    onClick={openModalExchange}
                  >
                    <div className={`${styles.btniconContainer} me-2`}>
                      <img
                        src={
                          contact.mode === "NIGHT"
                            ? exchangeWhite
                            : exchangeBlack
                        }
                      />
                    </div>
                    {t(`HOME_EXCHANGE`)}
                  </div>
                </div>
              </div>
              <PopUpExample
                popup={popup}
                success={(e: boolean) => {
                  setPopup(e);
                  downloadContact();
                }}
                close={(e: boolean) => {
                  setPopup(e);
                }}
                mode={contact.mode}
              />
              <PopUpExchange
                name={contact.name}
                popup={popupExchange}
                success={(e: boolean) => {
                  setPopupExchange(e);
                }}
                close={(e: boolean) => {
                  setPopupExchange(e);
                }}
                mode={contact.mode}
              />
              <div className="d-flex  flex-column align-items-start justify-content-start mt-5 ps-5 col-12 ">
                {contact.phone && (
                  <a
                    href={`tel:${contact.phone}`}
                    style={{ textDecoration: "none" }}
                    className=" col-12"
                  >
                    <span
                      className={`text-business  d-flex flex-row align-items-center justify-content-start col-12   ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      <div className={`${styles.iconContainer} me-3`}>
                        <img
                          src={
                            contact.mode === "NIGHT" ? phonewhite : phoneblack
                          }
                        />
                      </div>
                      {contact?.phone}
                    </span>
                  </a>
                )}
                {contact.email && (
                  <a
                    href={`mailto:${contact.email}`}
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className={`text-business mt-3  d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      <div className={`${styles.iconContainer} me-3`}>
                        <img
                          src={contact.mode === "NIGHT" ? mailwhite : mailblack}
                        />
                      </div>
                      {contact?.email}
                    </span>
                  </a>
                )}
                {contact.website && (
                  <a
                    href={`${contact.website}`}
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className={`text-business mt-3 d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      {/* <img src={websiteIcon} style={{color:'#ffffff', maxHeight:'30px'}}/> */}
                      <div className={`${styles.iconContainer} me-3`}>
                        <img
                          src={contact.mode === "NIGHT" ? pcwhite : pcblack}
                        />
                      </div>
                      {contact?.website.substring(8, contact.website.length)}
                    </span>
                  </a>
                )}

                {contact.label_link1 && contact.link1 && (
                  <a
                    href={`${contact.link1}`}
                    style={{ textDecoration: "underline" }}
                  >
                    <span
                      className={`text-business mt-3 d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      {/* <img src={websiteIcon} style={{color:'#ffffff', maxHeight:'30px'}}/> */}
                      {contact?.label_link1}
                    </span>
                  </a>
                )}

                {contact.label_link2 && contact.link2 && (
                  <a
                    href={`${contact.link2}`}
                    style={{ textDecoration: "underline" }}
                  >
                    <span
                      className={`text-business mt-3 d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      } ${styles.websitestring}`}
                    >
                      {/* <img src={websiteIcon} style={{color:'#ffffff', maxHeight:'30px'}}/> */}
                      {contact?.label_link2}
                    </span>
                  </a>
                )}
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center">
                {(contact?.facebook ||
                  contact?.linkedin ||
                  contact?.instagram ||
                  contact?.whatsapp !== 0) && (
                  <span
                    className={`text-business fw-bold mt-5 ${
                      contact.mode === "NIGHT" ? "color-white" : "color-black"
                    }`}
                  >
                    Social:
                  </span>
                )}
                <div className=" d-flex  flex-row align-items-center justify-content-around col-8  mt-5 pb-5">
                  {contact.linkedin && (
                    <span
                      className={`${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      }`}
                    >
                      <FontAwesomeIcon
                        onClick={() => {
                          contact?.linkedin &&
                            window.location.replace(contact?.linkedin);
                        }}
                        icon={faLinkedin as IconProp}
                        fontSize={"6vh"}
                      />
                    </span>
                  )}
                  {contact.instagram && (
                    <span
                      className={`bg-white d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "NIGHT" ? "color-white" : "color-black"
                      }`}
                      style={{
                        borderRadius: "50%",
                        height: "6vh",
                        width: "6vh",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faInstagram as IconProp}
                        onClick={() => {
                          contact?.instagram &&
                            window.location.replace(contact?.instagram);
                        }}
                        fontSize={"4.5vh"}
                        className="color-black"
                      />
                    </span>
                  )}
                  {contact.facebook &&
                    (console.log(contact.facebook),
                    (
                      <span>
                        <FontAwesomeIcon
                          icon={faFacebook as IconProp}
                          onClick={() => {
                            contact?.facebook &&
                              window.location.replace(contact?.facebook);
                          }}
                          fontSize={"6vh"}
                          style={
                            contact.mode === "LIGHT"
                              ? { color: "black" }
                              : { color: "white" }
                          }
                        />
                      </span>
                    ))}
                  {contact.whatsapp !== 0 && (
                    <span
                      className={`bg-white d-flex flex-row align-items-center justify-content-center ${
                        contact.mode === "LIGHT" ? "color-black" : "color-white"
                      }`}
                      style={{
                        borderRadius: "50%",
                        height: "6vh",
                        width: "6vh",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faWhatsapp as IconProp}
                        onClick={() => {
                          contact?.whatsapp !== 0 &&
                            window.location.replace(
                              `https://wa.me/${contact.phone.substring(
                                1,
                                contact.phone.length
                              )}`
                            );
                        }}
                        fontSize={"4.5vh"}
                        className="color-black"
                      />
                    </span>
                  )}
                </div>
              </div>
            </main>
            <div
              className={`  ${
                contact.mode === "NIGHT" ? "bg-black" : "bg-white"
              } ${styles.powered}`}
              onClick={() => (window.location.href = "https://dlctech.it")}
            >
              <span
                className={`${
                  contact.mode === "NIGHT" ? "color-white" : "color-black"
                } `}
              >
                powered by
              </span>
              <img
                className="ms-2"
                src={contact.mode === "NIGHT" ? dlclogo : dlclogoBlack}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
